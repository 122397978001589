import { useState } from "react"
import { Link } from "react-router-dom"
// CSS
import "react-responsive-carousel/lib/styles/carousel.min.css"
// Components
import { Carousel } from "react-responsive-carousel"
// Assets
import gogreen from "../assets/imgs/gogree.png"
import aboutcompany from "../assets/imgs/aboutcompany.png"
import howitwork from '../assets/imgs/howitwork.png'

export default function About() {
    const plots = ["residential", "commercial", "villas"]
    const [myPlot, setMymyPlot] = useState("residential")

    const [selected, setSelected] = useState(null)
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }

    // Uncomment it if needed
    /* const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    } */

    return (
        <>
            <section
                id="hero"
                className="hero1 align-items-center justify-content-center"
            >
                <div className="container" data-aos="fade-up">
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="col-xl-4 col-md-4">
                            <div>
                                <img
                                    id="gogreen"
                                    src={gogreen}
                                    alt="go green"
                                />
                            </div>
                        </div>

                        <div className="col-xl-8 col-md-8">
                            <div className="icon-box">
                                <p>[ ABOUT COMPANY ]</p>
                                <h2 id="headings">Our Vision & Mission</h2>
                                <div id="textarea">
                                    <p>
                                        We envision an inclusive Bunyaad Housing
                                        where sustainability is an integral part
                                        of the institutional ethos. This will
                                        inspire ecologically sound, socially
                                        just and financially prudent action that
                                        improves the well-being of people and
                                        the environment, and positions Lehigh as
                                        a forward-looking local and global
                                        collaborator and leader. Our mission is
                                        to advance solutions to support long
                                        term sustainable change in Bunyaad’s
                                        operations, academics, administration
                                        and community. We will meet the evolving
                                        challenges of a changing world, mitigate
                                        the effects of climate change and
                                        develop and empower future leaders. The
                                        alignment of these sustainability values
                                        with our practices embodies Bunyaad’s
                                        motto.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                id="hero"
                className="hero2 align-items-center justify-content-center"
            >
                <div className="container" data-aos="fade-up">
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="col-xl-4 col-md-4">
                            <div>
                                <img
                                    id="gogreen"
                                    src={aboutcompany}
                                    alt="go green"
                                />
                            </div>
                        </div>

                        <div className="col-xl-8 col-md-8">
                            <div className="icon-box">
                                <p>[ ABOUT COMPANY ]</p>
                                <h2 id="headings">Bunyaad Housing</h2>
                                <div
                                    id="textarea"
                                    style={{ textAlign: "center" }}
                                >
                                    {plots.map((mp) => (
                                        <button
                                            id="teambtn"
                                            className={`teambtn btn btn-light border-dark  
                                            ${myPlot === mp ? 'btn-hover': ''}`}
                                            onClick={() => setMymyPlot(mp)}
                                        >
                                            {mp.toLocaleUpperCase()}
                                        </button>
                                    ))}
                                </div>
                                {myPlot === "residential" && resdata()}
                                {myPlot === "commercial" && comdata()}
                                {myPlot === "villas" && Villadata()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                id="hero"
                className="hero1 align-items-center justify-content-center"
            >
                <div className="container" data-aos="fade-up">
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="row">
                            <div className="col-xl-8 col-xs-8">
                                <div className="icon-box">
                                    <p>[ HOW IT WORKS ]</p>
                                    <h2 id="headings">Our Work Process</h2>

                                    <div id="textarea">
                                        {data.map((item, i) => (
                                            <div className="item">
                                                <div
                                                    className="title"
                                                    onClick={() => toggle(i)}
                                                >
                                                    <h6>{item.question}</h6>
                                                    <span>
                                                        {selected === i
                                                            ? "-"
                                                            : "+"}
                                                    </span>
                                                </div>
                                                <p
                                                    className={
                                                        selected === i
                                                            ? "content show"
                                                            : "content"
                                                    }
                                                >
                                                    {item.answer}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-2">
                                <div>
                                    <img
                                        id="gogreen"
                                        src={howitwork}
                                        alt="go green"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                id="hero"
                className="hero2 align-items-center justify-content-center"
            >
                <div className="container" data-aos="fade-up">
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="row">
                            <div className="icon-box">
                                <p>[ POSITIVE FEEDBACK ]</p>
                                <h2 id="headings">What People Say</h2>
                                <div id="textarea">
                                    <Carousel infiniteLoop autoPlay>
                                        {cus_feeback.map((item) => (
                                            <div className="commentbox">
                                                <div id="cusname">
                                                    <h3>{item.customer}</h3>
                                                </div>
                                                <div id="commentbody">
                                                    <img
                                                        src={item.img}
                                                        id="customerimg"
                                                        alt="customer"
                                                    />
                                                    <p>[{item.ctype}]</p>
                                                    <h5>{item.comment}</h5>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const cus_feeback = [
    {
        img: require("../assets/imgs/gogree.jpg"),
        ctype: "COMPANY CLIENT",
        customer: "Muhammad Salah",
        comment:
            "High Quality Product with very competitive prices. So happy to invest with Bunyaad Housing Society",
    },
    {
        img: require("../assets/imgs/kamran.jpeg"),
        ctype: "UNITED KINGDOM",
        customer: "Sardar Kamran",
        comment:
            "You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.",
    },
    {
        img: require("../assets/imgs/ali.jpeg"),
        ctype: "AUSTRALIA",
        customer: "Ali Khan",
        comment:
            "You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.",
    },
]
const data = [
    {
        question: "Is Bunyaad Housing is sustainable project? ",
        answer: "Yes, We are fully focused to make this first complete sustainable society of Pakistan with embedded Ideas like Solar Panel, Waste Recycling & Water Recycling.",
    },
    {
        question: "How I can reach sales agent?",
        answer: "Mashhori is official sales partner of Bunyaad Housing Society. Reach out our helpline anytime at 0310 4-345-678",
    },
    {
        question: "How I can reach sales agent?",
        answer: "Bunyaad is focused on every details regarding designs. Our workprocess is to go through every point of design from sketch-draft to 3D.",
    },
]

const resdata = () => {
    return (
        <div className="row subbtn">
            <h2>
                Bunyaad Housing Society is offering multifold plans for
                residential plots{" "}
            </h2>
            <a>
                <Link className=" nav-link scrollto" to="/plotdetails" />
                <i className="bx bx-arrow-from-left"></i>5 Marla plot
            </a>
            <a>
                <Link className=" nav-link scrollto" to="/plotdetails" />
                <i className="bx bx-arrow-from-left"></i>7 Marla plot
            </a>
            <a>
                <Link className=" nav-link scrollto" to="/plotdetails" />
                <i className="bx bx-arrow-from-left"></i>10 Marla plot
            </a>
            <a>
                <Link className=" nav-link scrollto" to="/plotdetails" />
                <i className="bx bx-arrow-from-left"></i>1 Kanal plot
            </a>
        </div>
    )
}

const comdata = () => {
    return (
        <div className="row subbtn">
            <h2>
                Book your residential space at prime location of Rawalpindi{" "}
            </h2>
            <a>
                <i className="bx bx-arrow-from-left"></i>5 Marla plot
            </a>
            <a>
                <i className="bx bx-arrow-from-left"></i>10 Marla plot
            </a>
            <a>
                <i className="bx bx-arrow-from-left"></i>1 Kanal plot
            </a>
        </div>
    )
}

const Villadata = () => {
    return (
        <div className="row subbtn">
            <h2>
                Bunyaad Housing society is offering state-of-the-art Double
                storey Villas with 6 years easy intallments.{" "}
            </h2>
            <a>
                <i className="bx bx-arrow-from-left"></i>Book Now
            </a>
        </div>
    )
}
