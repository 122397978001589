/**
 * Assets
 */
import layout1 from "../assets/imgs/layout1.png"
import layout2 from "../assets/imgs/layout2.png"
import villa01 from "../assets/imgs/villa01.png"
import villa02 from "../assets/imgs/villa02.png"
import finalvalla1 from "../assets/imgs/BunyaadVillas10.png"
import finalvilla from "../assets/imgs/11.png"

export default function Dsv() {
   
    return (
        <>
            <section
                id="hero"
                className="hero1 align-items-center justify-content-center"
            >
                <div className="container" data-aos="fade-up">
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="icon-box">
                            <div className="row">
                                <div className="">
                                    <h2 id="headings">
                                        [ Bunyaad Housing | Double Storey Villa
                                        in Rawalpindi ]
                                    </h2>
                                    <h1>Price Plan For Double Storey Villa</h1>
                                    <div id="plottable" style={{overflow:"auto"}}>
                                      
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        Booking (10%)
                                                    </th>
                                                    <th scope="col">
                                                        Cofirmation
                                                    </th>
                                                    <th scope="col">
                                                        Installments*60
                                                    </th>
                                                    <th scope="col">
                                                        Bi-Annual
                                                    </th>
                                                    <th scope="col">
                                                        Possession (25%)
                                                    </th>
                                                    <th scope="col">
                                                        Total Price
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">650,000</th>
                                                    <td>650,000</td>
                                                    <td>48,750</td>
                                                    <td>130,000</td>
                                                    <td>975,000</td>
                                                    <td>65,000,00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container " data-aos="fade-up">
                    <h2 id="headings">
                        [ Bunyaad Housing | Double Storey Villa in Rawalpindi ]
                    </h2>
                    <div
                        className="row gy-4 mt-5 justify-content-center hero2"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="icon-box">
                            <div className="row">
                                <div className="col-xl-6 col-md-6 img-fluid" >
                                    <img
                                        src={layout1}
                                        className="villasimg img-fluid"
                                        alt="layout1"
                                    />
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <img
                                        src={layout2}
                                        className="villasimg img-fluid"
                                        alt="layout2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container " data-aos="fade-up">
                    <h2 id="headings">[ Double Story Villa 3D Model ]</h2>
                    <div
                        className="row gy-4 mt-5 justify-content-center hero2"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="icon-box">
                            <div className="row">
                                <div className="col-xl-6 col-md-6">
                                    <img
                                        src={villa01}
                                        className="villasimg img-fluid"
                                        alt="villa01"
                                    />
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <img
                                        src={villa02}
                                        className="villasimg img-fluid"
                                        alt="villa02"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container " data-aos="fade-up">
                    <h2 id="headings">[ Double Story Villa Final Look ]</h2>
                    <div
                        className="row gy-4 mt-5 justify-content-center hero2"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="icon-box">
                            <div className="row">
                                <div className="col-xl-12 col-md-12">
                                    <img
                                        src={finalvilla}
                                        className="finalvilla img-fluid"
                                        alt="final villa 1"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-md-12">
                                    <img
                                        src={finalvalla1}
                                        className="finalvilla img-fluid"
                                        alt="final villa 2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
