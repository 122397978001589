import work from "../assets/imgs/work.png"

export default function Developement() {
    return (
        <>
            <section
                id="hero"
                className="hero1 align-items-center justify-content-center"
            >
                <div className="container" data-aos="fade-up">
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="col-xl-5 col-md-5">
                            <div className="icon-box">
                                <div>
                                    <img
                                        src={work}
                                        style={{ width: "500px" }}
                                        alt="work in progress"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-5 col-md-5">
                            <div className="icon-box">
                                <p>[ work progress ]</p>
                                <h2 id="headings">
                                    Topographic Survey Completed
                                </h2>
                                <div id="textarea">
                                    <p>
                                        In present day planning, a geological
                                        guide or geographical sheet is a kind of
                                        guide described by enormous scope detail
                                        and quantitative portrayal of help
                                        highlights, normally utilizing form
                                        lines, yet generally utilizing an
                                        assortment of strategies. Bunyaad
                                        Housing Completed Topographic Survey on
                                        20th January 2022
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
