import { Suspense, lazy } from "react"

const IntroVideo = lazy(() => import("../components/IntroVideo"))

export default function home() {
    return (
        <>
            <section
                id="hero"
                className="d-flex align-items-center justify-content-center"
            >
                <Suspense fallback={<div>Loading...</div>}>
                    <IntroVideo />
                </Suspense>
                <div
                    className="container"
                    data-aos="fade-up"
                    style={{ bottom: "0" }}
                >
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="col-xl-3 col-md-4 ">
                            <div className="icon-box villas">
                                <i className="ri-store-line"></i>
                                <h3>
                                    <a href="/dsv">DOUBLE STOREY VILLA</a>
                                </h3>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-4">
                            <div className="icon-box residential">
                                <i className="ri-bar-chart-box-line"></i>
                                <h3>
                                    <a href="/plotdetails">RESIDENTIAL PLOTS</a>
                                </h3>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-4">
                            <div className="icon-box stateoftheart">
                                <i className="ri-calendar-todo-line"></i>
                                <h3>
                                    <a href="">STATE OF THE ART AMENITIES</a>
                                </h3>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-4">
                            <div className="icon-box sustain">
                                <i className="ri-paint-brush-line"></i>
                                <h3>
                                    <a href="">SUSTAINABLE LIVING</a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <button
                href="#"
                className="back-to-top d-flex align-items-center justify-content-center"
            >
                <i className="bi bi-arrow-up-short"></i>
            </button>
        </>
    )
}
