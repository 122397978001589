import React from 'react'
import '../assets/css/style.css'
export default function  Fileverification(props) {
  return (
    <>
    <section     id="hero"     className="hero1 align-items-center justify-content-center" >
    <h2 style={{textAlign:'center'}} id="headings">File Verification {props.src}</h2>
    <div className="container" data-aos="fade-up" style={{}}>
        
    <img className='img-fluid' src={require('../assets/imgs/files/'+props.src+'.jpg')} />
    </div>
    
</section>
    <div>

       
    </div>
    </>
  )
}
