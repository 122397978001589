export default function Contact() {
    return (
        <>
         <section id="hero" className="hero1 align-items-center justify-content-center">
       
       <div className="container" data-aos="fade-up">
       <div className="icon-box">

         <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">

           <div className="col-xl-5 col-md-5">

             <div>
               <h2>Get In Touch</h2>
               <form className='f'>

                 <input type='text' className='form-control' placeholder='Full Name' />
                 <input type='text' className='form-control' placeholder='Email' />
                 <input type='text' className='form-control' placeholder='Mobile No' />
                 <select type='text' className='form-control' placeholder='Interested In' >
                   <option>Interested In ?</option>
                   <option>Villa</option>
                   <option>Plot</option>
                 </select>
                 <textarea type='textarea' className='form-control' placeholder='Your Message' />
                 <input className='form-control btn btn-danger' type='submit' value='Send' />
               </form>
             </div>
           </div>

           <div className="col-xl-5 col-md-5">

             <iframe className='map' scrolling="no" marginHeight="0" marginWidth="0" title="bunyaad housing" aria-label="bunyaad housing" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d852093.5317366143!2d73.266933!3d33.462582!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbbbb93eca9972be1!2sMashhori.com!5e0!3m2!1sen!2sus!4v1666692610021!5m2!1sen!2sus" frameBorder="0"></iframe>
           </div>
         </div>


       </div>
     </div>
   </section>
   <section id="hero" className="hero2 align-items-center justify-content-center">

     <div className="container" data-aos="fade-up">
       <div className="icon-box">
         <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">

           <p>[ CONTACT DETAILS ]</p>
           <h2 id='headings'>VISIT OUR OFFICE</h2>
           <div id='textarea'>
             <p>
               Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions
             </p>
             <h2><i className="bx bx-current-location"></i> OUR ADDRESS </h2>
             <p>Plaza 33, Westeria Road, DHA II, Islamabad</p>

             <h2><i className="bx bx-envelope-open"></i> OUR MAILBOX </h2>
             <p>Plaza 33, Westeria Road, DHA II, Islamabad</p>

             <h2><i className="bx bx-mobile-vibration"></i> OUR PHONE </h2>
             <p>+92 310 4 3456 78</p>

             <div className="social-links mt-3" id='socialteam'>
               <a href="https://twitter.com/mashhorimedia" className="twitter"><i className="bx bxl-twitter"></i></a>
               <a href="https://www.facebook.com/bunyaadhousing" className="facebook"><i className="bx bxl-facebook"></i></a>
               <a href="https://www.instagram.com/bunyaadhousing/" className="instagram"><i className="bx bxl-instagram"></i></a>

               <a href="https://www.linkedin.com/mashhorimedia?_l=en_US" className="linkedin"><i className="bx bxl-linkedin"></i></a>
             </div>
           </div>
         </div>





       </div>

     </div>

   </section>
        </>
    )
}
