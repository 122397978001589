import { lazy, Suspense } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

/**
 * Pages
 */
import Home from "./pages/Home"
import About from "./pages/About"
import Team from "./pages/Team"
import Contact from "./pages/Contact"
import Development from "./pages/Development"
import PlotsDetails from "./pages/PlotsDetails"
import Readnames from "./components/readnames"
import Fileverification from "./components/fileverification"
import Dsv from "./pages/Dsv"

/**
 * Components
 */
const Header = lazy(() => import("./components/Header"))
const Footer = lazy(() => import("./components/Footer"))

function App() {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Header />
                <Routes>

                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/development" element={<Development />} />
                    <Route path="/plotdetails" element={<PlotsDetails />} />
                    <Route path="/dsv" element={<Dsv />} />
                    <Route path="/read" element={<Readnames />} />

                    <Route path="/m5-3131" element={<Fileverification src="m5-3131" />} />
                    <Route path="/ffm6onpe5z8ulhrf7wko1phbtlosllb-91e6ejidpp0" element={<Fileverification src="ffm6onpe5z8ulhrf7wko1phbtlosllb-91e6ejidpp0" />} />
                    <Route path="/m5-0130" element={<Fileverification src="m5-0130" />} />
                    <Route path="/m5-0199" element={<Fileverification src="m5-0199" />} />
                    <Route path="/M5-0506" element={<Fileverification src="M5-0506" />} />
                    <Route path="/M5-0611" element={<Fileverification src="M5-0611" />} />
                    <Route path="/m5-0788" element={<Fileverification src="m5-0788" />} />
                    <Route path="/m5-0801" element={<Fileverification src="m5-0801" />} />
                    <Route path="/m5-0822" element={<Fileverification src="m5-0822" />} />
                    <Route path="/M5-0828" element={<Fileverification src="M5-0828" />} />
                    <Route path="/m5-0876" element={<Fileverification src="m5-0876" />} />
                    <Route path="/m5-0877" element={<Fileverification src="m5-0877" />} />
                    <Route path="/M5-1011" element={<Fileverification src="M5-1011" />} />
                    <Route path="/M5-1054" element={<Fileverification src="M5-1054" />} />
                    <Route path="/m5-1076" element={<Fileverification src="m5-1076" />} />
                    <Route path="/M5-1177" element={<Fileverification src="M5-1177" />} />
                    <Route path="/m5-1200" element={<Fileverification src="m5-1200" />} />
                    <Route path="/M5-1372" element={<Fileverification src="M5-1372" />} />
                    <Route path="/M5-1487" element={<Fileverification src="M5-1487" />} />
                    <Route path="/M5-1677" element={<Fileverification src="M5-1677" />} />
                    <Route path="/m5-1699" element={<Fileverification src="m5-1699" />} />
                    <Route path="/M5-1727" element={<Fileverification src="M5-1727" />} />
                    <Route path="/M5-1732" element={<Fileverification src="M5-1732" />} />
                    <Route path="/m5-1847" element={<Fileverification src="m5-1847" />} />
                    <Route path="/m5-2144" element={<Fileverification src="m5-2144" />} />
                    <Route path="/m5-2255" element={<Fileverification src="m5-2255" />} />
                    <Route path="/m5-2266" element={<Fileverification src="m5-2266" />} />
                    <Route path="/M5-2269" element={<Fileverification src="M5-2269" />} />
                    <Route path="/M5-2278" element={<Fileverification src="M5-2278" />} />
                    <Route path="/m5-2286" element={<Fileverification src="m5-2286" />} />
                    <Route path="/M5-2287" element={<Fileverification src="M5-2287" />} />
                    <Route path="/m5-2288" element={<Fileverification src="m5-2288" />} />
                    <Route path="/M5-2611" element={<Fileverification src="M5-2611" />} />
                    <Route path="/m5-2798" element={<Fileverification src="m5-2798" />} />
                    <Route path="/M5-2929" element={<Fileverification src="M5-2929" />} />
                    <Route path="/m5-2967" element={<Fileverification src="m5-2967" />} />
                    <Route path="/M5-3077" element={<Fileverification src="M5-3077" />} />
                    <Route path="/m5-3146" element={<Fileverification src="m5-3146" />} />
                    <Route path="/m5-3267" element={<Fileverification src="m5-3267" />} />
                    <Route path="/m5-3299" element={<Fileverification src="m5-3299" />} />
                    <Route path="/M5-3487" element={<Fileverification src="M5-3487" />} />
                    <Route path="/m5-3488" element={<Fileverification src="m5-3488" />} />
                    <Route path="/M5-3565" element={<Fileverification src="M5-3565" />} />
                    <Route path="/m5-3575" element={<Fileverification src="m5-3575" />} />
                    <Route path="/M5-3588" element={<Fileverification src="M5-3588" />} />
                    <Route path="/M5-3799" element={<Fileverification src="M5-3799" />} />
                    <Route path="/m5-3804" element={<Fileverification src="m5-3804" />} />
                    <Route path="/m5-3804" element={<Fileverification src="m5-3804" />} />
                    <Route path="/m5-3888" element={<Fileverification src="m5-3888" />} />
                    <Route path="/M5-3899" element={<Fileverification src="M5-3899" />} />
                    <Route path="/M5-3900" element={<Fileverification src="M5-3900" />} />
                    <Route path="/m5-4122" element={<Fileverification src="m5-4122" />} />
                    <Route path="/m5-4163" element={<Fileverification src="m5-4163" />} />
                    <Route path="/M5-4261" element={<Fileverification src="M5-4261" />} />
                    <Route path="/M5-4266" element={<Fileverification src="M5-4266" />} />
                    <Route path="/m5-4448" element={<Fileverification src="m5-4448" />} />
                    <Route path="/m5-4488" element={<Fileverification src="m5-4488" />} />
                    <Route path="/m5-4576" element={<Fileverification src="m5-4576" />} />
                    <Route path="/m5-4590" element={<Fileverification src="m5-4590" />} />
                    <Route path="/M5-4611" element={<Fileverification src="M5-4611" />} />
                    <Route path="/m5-4630" element={<Fileverification src="m5-4630" />} />
                    <Route path="/M5-4766" element={<Fileverification src="M5-4766" />} />
                    <Route path="/m5-4786" element={<Fileverification src="m5-4786" />} />
                    <Route path="/m5-4786" element={<Fileverification src="m5-4786" />} />
                    <Route path="/m5-4799" element={<Fileverification src="m5-4799" />} />
                    <Route path="/m5-4807" element={<Fileverification src="m5-4807" />} />
                    <Route path="/m5-4807" element={<Fileverification src="m5-4807" />} />
                    <Route path="/m5-4809" element={<Fileverification src="m5-4809" />} />
                    <Route path="/M5-4822" element={<Fileverification src="M5-4822" />} />
                    <Route path="/m5-4830" element={<Fileverification src="m5-4830" />} />
                    <Route path="/m5-4862" element={<Fileverification src="m5-4862" />} />
                    <Route path="/m5-4933" element={<Fileverification src="m5-4933" />} />
                    <Route path="/m5-4977" element={<Fileverification src="m5-4977" />} />
                    <Route path="/M5-5011" element={<Fileverification src="M5-5011" />} />
                    <Route path="/m5-5044" element={<Fileverification src="m5-5044" />} />
                    <Route path="/m5-5071" element={<Fileverification src="m5-5071" />} />
                    <Route path="/m5-5085" element={<Fileverification src="m5-5085" />} />
                    <Route path="/m5-5199" element={<Fileverification src="m5-5199" />} />
                    <Route path="/M5-5244" element={<Fileverification src="M5-5244" />} />
                    <Route path="/m5-5865" element={<Fileverification src="m5-5865" />} />
                    <Route path="/m5-6007" element={<Fileverification src="m5-6007" />} />
                    <Route path="/M5-6009" element={<Fileverification src="M5-6009" />} />
                    <Route path="/m5-6044" element={<Fileverification src="m5-6044" />} />
                    <Route path="/m5-6088" element={<Fileverification src="m5-6088" />} />
                    <Route path="/m5-2248" element={<Fileverification src="m5-2248" />} />
 
                    <Route path="/m5-69711" element={<Fileverification src="m5-69711" />} />
                    <Route path="/M5-9944" element={<Fileverification src="M5-9944" />} />
                    <Route path="/m5-9877" element={<Fileverification src="m5-9877" />} />
                    <Route path="/m5-9870" element={<Fileverification src="m5-9870" />} />
                    <Route path="/M5-9866" element={<Fileverification src="M5-9866" />} />
                    <Route path="/m5-9746" element={<Fileverification src="m5-9746" />} />
                    <Route path="/m5-9722" element={<Fileverification src="m5-9722" />} />
                    <Route path="/M5-9610" element={<Fileverification src="M5-9610" />} />
                    <Route path="/m5-9487" element={<Fileverification src="m5-9487" />} />
                    <Route path="/M5-9477" element={<Fileverification src="M5-9477" />} />
                    <Route path="/m5-9466" element={<Fileverification src="m5-9466" />} />
                    <Route path="/m5-9360" element={<Fileverification src="m5-9360" />} />
                    <Route path="/M5-9311" element={<Fileverification src="M5-9311" />} />
                    <Route path="/m5-9300" element={<Fileverification src="m5-9300" />} />
                    <Route path="/m5-9266" element={<Fileverification src="m5-9266" />} />
                    <Route path="/M5-9244" element={<Fileverification src="M5-9244" />} />
                    <Route path="/m5-9077" element={<Fileverification src="m5-9077" />} />
                    <Route path="/m5-9040" element={<Fileverification src="m5-9040" />} />
                    <Route path="/m5-9033" element={<Fileverification src="m5-9033" />} />
                    <Route path="/M5-8949" element={<Fileverification src="M5-8949" />} />
                    <Route path="/m5-8860" element={<Fileverification src="m5-8860" />} />
                    <Route path="/m5-8822" element={<Fileverification src="m5-8822" />} />
                    <Route path="/m5-8794" element={<Fileverification src="m5-8794" />} />
                    <Route path="/m5-8767" element={<Fileverification src="m5-8767" />} />
                    <Route path="/M5-8750" element={<Fileverification src="M5-8750" />} />
                    <Route path="/M5-8677" element={<Fileverification src="M5-8677" />} />
                    <Route path="/m5-8652" element={<Fileverification src="m5-8652" />} />
                    <Route path="/m5-8502" element={<Fileverification src="m5-8502" />} />
                    <Route path="/m5-8166" element={<Fileverification src="m5-8166" />} />
                    <Route path="/m5-8100" element={<Fileverification src="m5-8100" />} />
                    <Route path="/m5-7998" element={<Fileverification src="m5-7998" />} />
                    <Route path="/M5-7982" element={<Fileverification src="M5-7982" />} />
                    <Route path="/m5-7966" element={<Fileverification src="m5-7966" />} />
                    <Route path="/m5-7950" element={<Fileverification src="m5-7950" />} />
                    <Route path="/m5-7922" element={<Fileverification src="m5-7922" />} />
                    <Route path="/m5-7879" element={<Fileverification src="m5-7879" />} />
                    <Route path="/m5-7861" element={<Fileverification src="m5-7861" />} />
                    <Route path="/m5-7800" element={<Fileverification src="m5-7800" />} />
                    <Route path="/m5-7722" element={<Fileverification src="m5-7722" />} />
                    <Route path="/M5-7610" element={<Fileverification src="M5-7610" />} />
                    <Route path="/m5-7560" element={<Fileverification src="m5-7560" />} />
                    <Route path="/M5-4055" element={<Fileverification src="M5-4055" />} />
                    <Route path="/m5-7555" element={<Fileverification src="m5-7555" />} />
                    <Route path="/M5-7522" element={<Fileverification src="M5-7522" />} />
                    <Route path="/m5-7424" element={<Fileverification src="m5-7424" />} />
                    <Route path="/m5-7396" element={<Fileverification src="m5-7396" />} />
                    <Route path="/m5-7363" element={<Fileverification src="m5-7363" />} />
                    <Route path="/M5-7299" element={<Fileverification src="M5-7299" />} />
                    <Route path="/m5-7122" element={<Fileverification src="m5-7122" />} />
                    <Route path="/M5-7091" element={<Fileverification src="M5-7091" />} />
                    <Route path="/M5-7081" element={<Fileverification src="M5-7081" />} />
                    <Route path="/m5-7077" element={<Fileverification src="m5-7077" />} />
                    <Route path="/M5-6989" element={<Fileverification src="M5-6989" />} />
                    <Route path="/m5-7033" element={<Fileverification src="m5-7033" />} />
                    <Route path="/M5-6977" element={<Fileverification src="M5-6977" />} />
                    <Route path="/m5-6964" element={<Fileverification src="m5-6964" />} />
                    <Route path="/M5-6951" element={<Fileverification src="M5-6951" />} />
                    <Route path="/m5-6843" element={<Fileverification src="m5-6843" />} />
                    <Route path="/m5-6822" element={<Fileverification src="m5-6822" />} />
                    <Route path="/M5-6811" element={<Fileverification src="M5-6811" />} />
                    <Route path="/m5-6733" element={<Fileverification src="m5-6733" />} />
                    <Route path="/m5-6549" element={<Fileverification src="m5-6549" />} />
                    <Route path="/m5-6499" element={<Fileverification src="m5-6499" />} />
                    <Route path="/m5-6422" element={<Fileverification src="m5-6422" />} />
                    <Route path="/m5-6379" element={<Fileverification src="m5-6379" />} />
                    <Route path="/M5-6302" element={<Fileverification src="M5-6302" />} />
                    <Route path="/m5-6190" element={<Fileverification src="m5-6190" />} />
                    <Route path="/m5-6161" element={<Fileverification src="m5-6161" />} />
                    <Route path="/M5-6144" element={<Fileverification src="M5-6144" />} />
                    <Route path="/m5-6102" element={<Fileverification src="m5-6102" />} />
                    <Route path="/m5-6088" element={<Fileverification src="m5-6088" />} />
                    <Route path="/M5-4388" element={<Fileverification src="M5-4388" />} />
                    <Route path="/M5-4055" element={<Fileverification src="M5-4055" />} />
                    <Route path="/M5-4411" element={<Fileverification src="M5-4411" />} />
                    <Route path="/M5-9837" element={<Fileverification src="M5-9837" />} />
    
                    <Route path="/m5-2967" element={<Fileverification src="m5-2967" />} />
                    <Route path="/m5-7921" element={<Fileverification src="m5-7921" />} />
                    <Route path="/m5-3131" element={<Fileverification src="m5-3131" />} />
                    <Route path="/m5-4477" element={<Fileverification src="m5-4477" />} />
                    <Route path="/m5-8767" element={<Fileverification src="m5-8767" />} />
                    <Route path="/m5-8673" element={<Fileverification src="m5-8673" />} />
                    <Route path="/m5-8271" element={<Fileverification src="m5-8271" />} />
                    <Route path="/m5-7499" element={<Fileverification src="m5-7499" />} />
                    <Route path="/m5-6573" element={<Fileverification src="m5-6573" />} />
                    <Route path="/m5-3419" element={<Fileverification src="m5-3419" />} />

                    <Route path="/M5-0688" element={<Fileverification src="M5-0688" />} />
                    <Route path="/M5-0878" element={<Fileverification src="M5-0878" />} />
                    <Route path="/M5-1017" element={<Fileverification src="M5-1017" />} />
                    <Route path="/M5-1272" element={<Fileverification src="M5-1272" />} />
                    <Route path="/M5-1575" element={<Fileverification src="M5-1575" />} />
                    <Route path="/m5-1584" element={<Fileverification src="m5-1584" />} />
                    <Route path="/M5-2400" element={<Fileverification src="M5-2400" />} />
                    <Route path="/M5-3911" element={<Fileverification src="M5-3911" />} />
                    <Route path="/M5-4255" element={<Fileverification src="M5-4255" />} />
                    <Route path="/M5-4722" element={<Fileverification src="M5-4722" />} />
                    <Route path="/M5-5445" element={<Fileverification src="M5-5445" />} />
                    <Route path="/M5-5840" element={<Fileverification src="M5-5840" />} />
                    <Route path="/M5-6011" element={<Fileverification src="M5-6011" />} />
                    <Route path="/M5-6707" element={<Fileverification src="M5-6707" />} />
                    <Route path="/M5-7070" element={<Fileverification src="M5-7070" />} />
                    <Route path="/M5-7211" element={<Fileverification src="M5-7211" />} />
                    <Route path="/M5-7323" element={<Fileverification src="M5-7323" />} />
                    <Route path="/M5-7433" element={<Fileverification src="M5-7433" />} />
                    <Route path="/M5-7811" element={<Fileverification src="M5-7811" />} />

                    <Route path="/M5-4177" element={<Fileverification src="M5-4177" />} />
                    <Route path="/M5-7055" element={<Fileverification src="M5-7055" />} />
                    <Route path="/M5-7094" element={<Fileverification src="M5-7094" />} />
                    <Route path="/M5-7188" element={<Fileverification src="M5-7188" />} />
                    <Route path="/M5-8622" element={<Fileverification src="M5-8622" />} />
                    <Route path="/M5-9788" element={<Fileverification src="M5-9788" />} />
                </Routes>
                <Footer />
            </Suspense>
        </Router>
    )
}

export default App
