import "react-responsive-carousel/lib/styles/carousel.min.css"

export default function PlotsDetails() {
    return (
        <>
            <section
                id="hero"
                className="hero1 align-items-center justify-content-center"
            >
                <div className="container" data-aos="fade-up">
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="col-xl-10 col-md-10">
                            <div className="icon-box">
                                <div id="plottable" style={{overflow:"auto"}}>
                                    <h2 id="headings">5 Marla Plots</h2>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Plot Size</th>
                                                <th scope="col">
                                                    Booking (10%)
                                                </th>
                                                <th scope="col">
                                                    Monthly Installments (65%)
                                                </th>
                                                <th scope="col">
                                                    Possession (25%)
                                                </th>
                                                <th scope="col">Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">05 Marla</th>
                                                <td>150,000</td>
                                                <td>16,200</td>
                                                <td>375,000</td>
                                                <td>15 Lac</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div id="plottable" style={{overflow:"auto"}}>
                                    <h2 id="headings">7 Marla Plots</h2>
                                    <table class="table" style={{overflow:"auto"}}>
                                        <thead>
                                            <tr>
                                                <th scope="col">Plot Size</th>
                                                <th scope="col">
                                                    Booking (10%)
                                                </th>
                                                <th scope="col">
                                                    Monthly Installments (65%)
                                                </th>
                                                <th scope="col">
                                                    Possession (25%)
                                                </th>
                                                <th scope="col">Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">07 Marla</th>
                                                <td>200,000</td>
                                                <td>21,600</td>
                                                <td>500,000</td>
                                                <td>2,000,000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div id="plottable" style={{overflow:"auto"}}>
                                    <h2 id="headings">10 Marla Plots</h2>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Plot Size</th>
                                                <th scope="col">
                                                    Booking (10%)
                                                </th>
                                                <th scope="col">
                                                    Monthly Installments (65%)
                                                </th>
                                                <th scope="col">
                                                    Possession (25%)
                                                </th>
                                                <th scope="col">Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">10 Marla</th>
                                                <td>280,000</td>
                                                <td>30,240</td>
                                                <td>700,000</td>
                                                <td>2,800,000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div id="plottable" style={{overflow:"auto"}}>
                                    <h2 id="headings">1 Kanal Plots</h2>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Plot Size</th>
                                                <th scope="col">
                                                    Booking (10%)
                                                </th>
                                                <th scope="col">
                                                    Monthly Installments (65%)
                                                </th>
                                                <th scope="col">
                                                    Possession (25%)
                                                </th>
                                                <th scope="col">Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1 Kanal</th>
                                                <td>550,000</td>
                                                <td>59,400</td>
                                                <td>1,375,000</td>
                                                <td>5,500,000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
