import { useState } from "react"

/**
 * Assets
 */
import ceo from "../assets/imgs/naseebceo.png"
import nawazish from "../assets/imgs/nawazish.jpg"
import shah from "../assets/imgs/shahzaibbun.jpg"
import fasih from "../assets/imgs/fasih01.png"
import muneeb from "../assets/imgs/muneeb.JPG"
import anum from "../assets/imgs/anum.jpg"
import diya from "../assets/imgs/diya.jpg"
import atif from "../assets/imgs/atif.JPG"
import jawab from "../assets/imgs/jawab.jpg"
import md from "../assets/imgs/md.png"
import awais from "../assets/imgs/awais.jpg"
import haseeb from "../assets/imgs/Haseeb2.jpg"
import arsalan from "../assets/imgs/arsalan.jpg"
import nouman from "../assets/imgs/nouman.jpeg"
import awaisaccount from "../assets/imgs/Awaisacc.jpg"
import { isVisible } from "@testing-library/user-event/dist/utils"

export default function Team() {
    const teamcat = ["Team IT", "sales & marketing", "directors", "accounts"]
    const [myTeam, setMyteam] = useState("Team IT")

    return (
        <>
            <section
                id="hero"
                className="hero1 align-items-center justify-content-center"
            >
                <div className="container" data-aos="fade-up">
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="icon-box">
                            <div className="row">
                                <div className="col-xl-3 col-md-3">
                                    <img id="gogreen" src={ceo} alt="CEO" />
                                </div>

                                <div className="col-xl-8 col-md-8">
                                    <h2 id="headings">[ CEO MESSAGE ]</h2>
                                    <h1>Naseeb Zada</h1>
                                    <div id="textarea">
                                        <p>
                                            In quest for our Group mission of
                                            “Changing our environmental factors,
                                            further developing our reality”, we
                                            expect to add to an economical
                                            society. This will be finished by
                                            tending to ecological open doors and
                                            satisfying our Corporate social
                                            obligations through proactive
                                            activities. We are completely
                                            dedicated to moderating the social
                                            effect of environmental change and
                                            the ecological weight by proactively
                                            tending to the decrease of ozone
                                            depleting substances created in the
                                            assembling system and giving items
                                            that add to energy protection. We
                                            endeavor to make a huge commitment
                                            to social orders and the climate.
                                            The manageability targets are set
                                            considering the drawn out essential
                                            needs of the Group. By dealing with
                                            the ecological effect of the
                                            exercises, we will keep on working
                                            in amicability with our nearby
                                            networks and challenge ourselves to
                                            further develop energy proficiency
                                            and asset the board. We keep on
                                            decreasing ozone depleting substance
                                            outflows through superior usage of
                                            our offices and energy saving
                                            drives.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                id="hero"
                className="hero2 align-items-center justify-content-center"
            >
                <div className="container" data-aos="fade-up">
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="icon-box">
                            <div className="row">
                                <div className="col-xl-8 col-md-8">
                                    <h2 id="headings">
                                        [ Managing Director Message]
                                    </h2>
                                    <h1>Sardar Umair Bashir</h1>
                                    <div id="textarea">
                                        <p>
                                            Bunyaad Housing society is a project
                                            that from its very beginning was a
                                            dream to deliver you the best, which
                                            could comply with all the future
                                            challenges and modern lifestyle.
                                            This housing project promises to be
                                            the most reliable, luxurious and
                                            wonderful product a Pakistani could
                                            afford. The trend of luxury and
                                            modern lifestyle has really killed
                                            the earth without kindness but
                                            Bunyaad among all the other
                                            contemporary housing schemes is the
                                            only one where luxury and
                                            eco-friendly environment go hand in
                                            hand. The new extension to
                                            Islamabad, Bunyaad is an elegantly
                                            planned housing scheme unlike
                                            anything witnessed before.{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-3">
                                    <img id="gogreen" src={md} alt="go green" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                id="hero"
                className="hero1 align-items-center justify-content-center"
            >
                <div className="container" data-aos="fade-up">
                    <div
                        className="row gy-4 mt-5 justify-content-center"
                        data-aos="zoom-in"
                        data-aos-delay="250"
                    >
                        <div className="icon-box">
                            <h2 id="headings">[TEAM BEHIND SUCCESS ]</h2>
                            <br />
                            {teamcat.map((tc, idx) => (
                                <button
                                    className={`teambtn btn btn-light border-dark  
                                ${myTeam === tc ? 'btn-hover': ''}`}
                                    onClick={() => setMyteam(tc)}
                                    key={idx}
                                >
                                    {tc.toLocaleUpperCase()}
                                </button>
                            ))}

                            <div>
                                {myTeam === "Team IT" && teamit("it")}
                                {myTeam === "sales & marketing" && teamsale("it")}
                                {myTeam === "directors" && teamland("it")}
                                {myTeam === "accounts" && teamaccounts("it")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const teamit = ({ it }) => {
    return (
        
        <div className="row" id="teamblock" >
            <div className="col-xl-3 col-md-3" id="socialteam">
                <img id="gogreen" src={fasih} alt="Fasih" />
                <h2>Fasihuddin</h2>
                <p>[ Information Technologies Officer ]</p>
                <div className="">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/bunyaadhousing"
                        className="facebook"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/bunyaadhousing/"
                        className="instagram"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/mashhorimedia?_l=en_US"
                        className="linkedin"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>

            <div className="col-xl-3 col-md-3" id="socialteam">
                <img id="gogreen" src={arsalan} alt="Muneeb" />
                <h2>Muhammad Arsalan</h2>
                <p>[ Director IT ]</p>
                <div className="social-links mt-3">
                    <a
                        href="https://twitter.com/M_Arsalan95"
                        className="twitter" target="_blank"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/arsho.jan"
                        className="facebook" target="_blank"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/m.arsalan.khann/"
                        className="instagram" target="_blank"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/in/muhammad-arsalan-231092159/"
                        className="linkedin" target="_blank"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>

            <div className="col-xl-3 col-md-3" id="socialteam">
                <img className="teampic" src={haseeb} alt="Haseeb" />
                <h2>Muhammad Haseeb Ahmed</h2>
                <p>[ Graphics Designer ]</p>
                <div className="social-links mt-3">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter" target="_blank"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/rajahaseeb.ahmedabbassi.1?mibextid=ZbWKwL"
                        className="facebook" target="_blank"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://instagram.com/haseeb.abbassi?igshid=MzNlNGNkZWQ4Mg=="
                        className="instagram" target="_blank"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/in/haseeb-abbasi-a6a664277/"
                        className="linkedin" target="_blank"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-3 col-md-3" id="socialteam">
                <img id="gogreen" src={muneeb} alt="Muneeb" />
                <h2>Muhammad Muneeb</h2>
                <p>[ Digital Content Writter ]</p>
                <div className="social-links mt-3">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/bunyaadhousing"
                        className="facebook"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/bunyaadhousing/"
                        className="instagram"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/mashhorimedia?_l=en_US"
                        className="linkedin"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>
           
        </div>
      
    )
}

const teamsale = ({ it }) => {
    return (
    
        <div className="row" id="teamblock">
            <div className="col-xl-3 col-md-3">
                <img id="gogreen" src={diya} alt="Diya" />
                <h2>Diya Mushtaq</h2>
                <p>[ Sales Manager ]</p>
                <div className="social-links mt-3" id="socialteam">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/bunyaadhousing"
                        className="facebook"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/bunyaadhousing/"
                        className="instagram"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>
                    <a
                        href="https://www.linkedin.com/mashhorimedia?_l=en_US"
                        className="linkedin"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-3 col-md-3" id="socialteam">
                <img id="gogreen" src={shah} alt="Shah" />
                <h2>Syed Shahzaib Ali</h2>
                <p>[ Senior Sales Executive ]</p>
                <div className="">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/bunyaadhousing"
                        className="facebook"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/bunyaadhousing/"
                        className="instagram"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/mashhorimedia?_l=en_US"
                        className="linkedin"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-3 col-md-3" id="socialteam">
                <img id="gogreen" src={atif} alt="Atif" />
                <h2>ch Atif</h2>
                <p>[ Senior Sales ]</p>
                <div className="social-links mt-3">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/bunyaadhousing"
                        className="facebook"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/bunyaadhousing/"
                        className="instagram"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/mashhorimedia?_l=en_US"
                        className="linkedin"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>
        </div>
    
    )
}

const teamland = ({ it }) => {
    return (
        <div className="row" id="teamblock">
            <div className="col-xl-3 col-md-3">
                <img id="gogreen" src={nawazish} alt="Nawazish" />
                <h2>Mirza Nawazish</h2>
                <p>[ Chief Technology Officer ]</p>
                <div className="social-links mt-3" id="socialteam">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/bunyaadhousing"
                        className="facebook"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/bunyaadhousing/"
                        className="instagram"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>
                    <a
                        href="https://www.linkedin.com/mashhorimedia?_l=en_US"
                        className="linkedin"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-3 col-md-3" id="socialteam">
                <img id="gogreen" src={jawab} alt="Jawab" />
                <h2>Jawad Hafeez</h2>
                <p>[ Land Director ]</p>
                <div className="">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/bunyaadhousing"
                        className="facebook"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/bunyaadhousing/"
                        className="instagram"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/mashhorimedia?_l=en_US"
                        className="linkedin"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-3 col-md-3" id="socialteam">
                <img id="gogreen" src={awais} alt="Jawab" />
                <h2>Malik Awais Khalid</h2>
                <p>[ Director Sales & Marketing ]</p>
                <div className="">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/bunyaadhousing"
                        className="facebook"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/bunyaadhousing/"
                        className="instagram"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/mashhorimedia?_l=en_US"
                        className="linkedin"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>
        </div>

        
        
    )
}

const teamaccounts = ({ it }) => {
    return (
        
        <div className="row" id="teamblock" >
            <div className="col-xl-3 col-md-3" id="socialteam">
                <img className="teampic" src={nouman} alt="Nouman" />
                <h2>Muhammad Nouman Ayaz</h2>
                <p>[ Accounts Manager ]</p>
                <div className="">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/bunyaadhousing"
                        className="facebook"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/bunyaadhousing/"
                        className="instagram"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/mashhorimedia?_l=en_US"
                        className="linkedin"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-3 col-md-3" id="socialteam">
                <img className="teampic" src={awaisaccount} alt="awaisaccount" />
                <h2>Awais</h2>
                <p>[ Accounts Assistant ]</p>
                <div className="social-links mt-3">
                    <a
                        href="https://twitter.com/mashhorimedia"
                        className="twitter"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/bunyaadhousing"
                        className="facebook"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/bunyaadhousing/"
                        className="instagram"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/mashhorimedia?_l=en_US"
                        className="linkedin"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                </div>
            </div>
            
        </div>
      
    )
}
