import React from 'react'


export default function Readnames() {
 

  return (
    <>
    <h1>file names</h1>
  
    </>
  )
}
